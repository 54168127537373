import { ErrorHandler, Injectable } from '@angular/core';
import { LogLevel } from '../../../../goldstar-share/src/app/services/logging/logModel';
import { ApiService } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { LogInfo } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { Exception } from '../../../../goldstar-share/src/app/services/logging/exception';
import { lastValueFrom } from 'rxjs';
import { AuthService } from '../components/auth/auth.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(
		private api: ApiService,
		private authService: AuthService
	) {}

	async handleError(error: any): Promise<void> {
		console.log(error);
		// Process only log of severity level error and critical
		if (error instanceof Exception) {
			const logDataRequest: LogInfo = {
				message: error.error.message,
				callStack: error.stack,
				logLevel: LogLevel.CRITICAL,
				source: error.sourceComponent,
				userEmail: this.authService.userId,
			};
			await lastValueFrom(this.api.putLogs({ body: logDataRequest }));
		}
	}

	private getComponentName(error: any): string {
		const stack = error.stack || '';
		const match = stack.match(/at (\w+)Component\.(\w+)/);
		return match ? `${match[1]}Component` : 'Unknown Component';
	}

	private getLineNumber(error: any): number {
		const stack = error.stack || '';
		const match = stack.match(/:(\d+):\d+\)/);
		return match ? parseInt(match[1], 10) : 0;
	}
}
